




































import { Component, Vue } from 'vue-property-decorator';
import {
  IVideo,
  IVideoUpdate,
  IVideoCreate,
} from '@/interfaces';
import { dispatchGetVideos, dispatchCreateVideo } from '@/store/admin/actions';

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public name: string = '';
  public duration: number = 0;
  public teacher: string = '';
  public date: string = '';
  public equipment: string = '';
  public level: number = 0;
  public minAge: number = 0;
  public maxAge: number = 0;
  public reference: string = '';
  public visibility: string = 'public';
  public previousVideoID: number | null = null;
  public categoryID: number | null = null;

  public async mounted() {
    await dispatchGetVideos(this.$store);
    this.reset();
  }

  public reset() {
    this.valid = false;
    this.name = '';
    this.reference = '';
    this.duration = 0;
    this.teacher = '';
    this.date = '';
    this.equipment = '';
    this.level = 0;
    this.minAge = 0;
    this.maxAge = 0;
    this.visibility = '';
    this.previousVideoID = null;
    this.categoryID = null;
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const createdVideo: IVideoCreate = {
        name: this.name,
        reference: this.reference,
        duration: this.duration,
        teacher: this.teacher,
        production_date: this.date,
        equipment: this.equipment,
        level: this.level,
        min_age: this.minAge,
        max_age: this.maxAge,
        visibility: this.visibility,
        previous_video_id: this.previousVideoID,
        category_id: this.categoryID,
      };
      if (this.previousVideoID) {
        createdVideo.previous_video_id = this.previousVideoID;
      }
      if (this.categoryID) {
        createdVideo.category_id = this.categoryID;
      }
      await dispatchCreateVideo(this.$store, createdVideo);
      this.$router.push('/main/admin/videos');
    }
  }
}
