



























import { Component, Vue } from 'vue-property-decorator';
import {
  ICategory,
  ICategoryUpdate,
  ICategoryCreate,
} from '@/interfaces';
import { dispatchGetCategories, dispatchCreateCategory } from '@/store/admin/actions';

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public name: string = '';
  public displayName: string = '';
  public parentID: number | null = null;

  public async mounted() {
    await dispatchGetCategories(this.$store);
    this.reset();
  }

  public reset() {
    this.name = '';
    this.parentID = null;
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const createdCategory: ICategoryCreate = {
        display_name: this.displayName,
        name: this.name,
      };
      if (this.parentID) {
        createdCategory.parent_id = this.parentID;
      }
      await dispatchCreateCategory(this.$store, createdCategory);
      await this.$router.push('/main/admin/categories');
    }
  }
}
