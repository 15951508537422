



























import { Component, Vue } from 'vue-property-decorator';
import {
  ISub,
  ISubUpdate,
  ISubCreate,
} from '@/interfaces';
import { dispatchGetSubs, dispatchCreateSub } from '@/store/admin/actions';

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public name: string = '';
  public price: number = 0.0;
  public hidden: boolean = false;

  public async mounted() {
    await dispatchGetSubs(this.$store);
    this.reset();
  }

  public reset() {
    this.valid = false;
    this.name = '';
    this.price = 0.0;
    this.hidden = false;
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const createdSub: ISubCreate = {
        name: this.name,
        price: this.price,
        hidden: this.hidden,
      };
      await dispatchCreateSub(this.$store, createdSub);
      this.$router.push('/main/admin/subs');
    }
  }
}
